import React from "react";
import { fetchApi } from "../../../Utiles/connections";
import { ApiRes } from "../../../Utiles/types";
import Loader3 from "../../Shared/Loader3/Loader3";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
    setUser: (user: any) => dispatch({ type: "SET_USER", payload: user }),
    setToken: (token: string) =>
      dispatch({ type: "SET_TOKEN", payload: token }),
    setReset: (reset: boolean) =>
      dispatch({ type: "SET_RESET", payload: reset }),
    setVerificationType: (type: string) =>
      dispatch({ type: "SET_VERIFICATION_TYPE", payload: type }),
  };
};

function ForgotPassword({
  setPath,
  setUser,
  setToken,
  setReset,
  setVerificationType,
}: any) {
  const navigate = useNavigate();
  const [Email, setEmail] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleNavigate = (path: string) => {
    navigate(path);
    setPath(path);
  };

  const SendVerification = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const response: ApiRes = await fetchApi(
      "AUTH/SendMemberVerificationEmail",
      "POST",
      {
        Email,
      }
    );
    if (response.StatusCode === 200) {
      handleNavigate("/verification");
      setUser({ ...response.My_Result, Email });
      setToken(response.My_Result.JWT_Token);
      setVerificationType("reset");
    } else {
      alert(response.ExceptionMsg);
    }
    setLoading(false);
  };

  return (
    <div className="membersLogin flex-column ">
      <div
        className="card shadow border-0 rounded-border p-3 text-center blur text-white"
        style={{ width: "30rem", maxWidth: "90%" }}>
        <h1>Reset Password</h1>
        <form className="d-flex flex-column" onSubmit={SendVerification}>
          <div className="">
            <label className="text-start w-100 mt-2 mb-1" htmlFor="Email">
              Email
            </label>
            <input
              name="Email"
              type="email"
              placeholder="Email"
              className="form-control "
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <button
            className="navIcons w-100 mb-3 mt-2 d-flex justify-content-center py-2"
            type="submit">
            {loading ? <Loader3 /> : "Send Verification Code"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
