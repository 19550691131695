import React, { useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { fetchApi } from "../../../Utiles/connections";
import { ApiRes } from "../../../Utiles/types";
import Loader3 from "../../Shared/Loader3/Loader3";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
    setToken: (token: string) =>
      dispatch({ type: "SET_TOKEN", payload: token }),
    setUser: (user: any) => dispatch({ type: "SET_USER", payload: user }),
    setVerificationType: (type: string) =>
      dispatch({ type: "SET_VERIFICATION_TYPE", payload: type }),
  };
};

const SignUp = ({
  path,
  setPath,
  token,
  setToken,
  setUser,
  setVerificationType,
}: any) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);

  const [Email, setEmail] = useState<string>("");
  const [Password, setPassword] = useState<string>("");
  const [FullName, setFullName] = useState<string>("");
  const [PhoneNumber, setPhoneNumber] = useState<string>("");
  const [ConfirmPassword, setConfirmPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleNavigate = (path: string) => {
    navigate(path);
    setPath(path);
  };

  const signUp = async (e: any) => {
    e.preventDefault();
    if (Password !== ConfirmPassword) {
      alert("Password does not match");
      return;
    }
    setLoading(true);
    const responce: ApiRes = await fetchApi("AUTH/MemberSignup", "POST", {
      Email,
      Password,
      FullName,
      Phone: PhoneNumber,
    });
    if (responce.StatusCode === 200) {
      setToken(responce.My_Result.JWT_Token);
      setUser({ ...responce.My_Result, Email });
      handleNavigate("/verification");
    } else {
      alert(responce.ExceptionMsg);
    }
    setLoading(false);
  };

  return (
    <div className="membersLogin flex-column ">
      <div
        className="card shadow border-0 rounded-border p-3 text-center blur text-white"
        style={{ width: "30rem", maxWidth: "90%" }}>
        <h1>Sign Up</h1>
        <form className="d-flex flex-column" onSubmit={signUp}>
          <div className="d-flex w-100 mt-2">
            <div className="me-2 w-50">
              <label className="text-start w-100" htmlFor="Name">
                Full Name
              </label>
              <input
                type="text"
                placeholder="Full Name"
                className="form-control"
                name="Name"
                value={FullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>
            <div className="w-50">
              <label className="text-start w-100" htmlFor="Phone">
                Phone Number
              </label>
              <input
                type="text"
                placeholder="ex: 1 123 456 78"
                className="form-control"
                value={PhoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                name="Phone"
                required
              />
            </div>
          </div>
          <label className="text-start w-100 mt-3">Email</label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="form-control"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label className="text-start w-100 mt-3" htmlFor="Password">
            Password
          </label>
          <div className=" d-flex align-items-center w-100 position-relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="form-control"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
              name="Password"
              required
            />
            <i
              className={`cursor-pointer text-dark bi bi-eye${
                showPassword ? "" : "-slash"
              }`}
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "0.6rem",
              }}></i>
          </div>
          <label className="text-start w-100 mt-3" htmlFor="ConfirmPassword">
            Confirm Password
          </label>
          <div className=" d-flex align-items-center w-100 mb-3 position-relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              className="form-control"
              value={ConfirmPassword}
              name="ConfirmPassword"
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <i
              className={`cursor-pointer text-dark bi bi-eye${
                showConfirmPassword ? "" : "-slash"
              }`}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{
                position: "absolute",
                right: "0.6rem",
              }}></i>
          </div>

          {/* <div className="d-flex justify-content-end">
          <a>Forgot Password?</a>
        </div> */}
          <div className="d-flex align-items-center pb-3 justify-content-between">
            <span>I want to sign up as a lawyer</span>
            <div className="">
              <label
                className="switch m-0"
                htmlFor="RequiresLawyer"
                style={{ scale: "0.7" }}>
                <input
                  id="RequiresLawyer"
                  type="checkbox"
                  // checked={contract.RequiresLawyer}
                  onChange={(e) =>
                    e
                      ? setVerificationType("lawyer")
                      : setVerificationType("email")
                  }
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <button className="navIcons d-flex justify-content-center w-100 mb-3">
            {loading ? <Loader3 /> : "Sign up"}
          </button>
        </form>
        <div className="d-flex flex-column align-items-center">
          Already have an account?
          <a onClick={() => handleNavigate("/login")}>Log in</a>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
