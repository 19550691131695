import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="p-4">
      <h1>Terms and Conditions for NovaLaw</h1>

      <h2>1. Introduction</h2>
      <p>
        These Terms and Conditions (the "Terms") govern your use of the online
        platform (the "Platform") owned and operated by NovaLaw Consultancy,
        referred hereafter by NovaLaw (the "Company"). By accessing or using the
        Platform, you agree to be bound by these Terms. If you do not agree to
        these Terms, you must not access or use the Platform.
      </p>

      <h2>2. Definitions</h2>
      <ul>
        <li>
          <strong>Account:</strong> A user account created on the Platform.
        </li>
        <li>
          <strong>Company:</strong> The entity that owns and operates the
          Platform, NovaLaw Consultancy LTD, 85 Great Portland Street, London,
          W1W 7LT, United Kingdom.
        </li>
        <li>
          <strong>Lawyer:</strong> A Lawyer on NovaLaw, is a certified lawyer
          registered in his specific Bar association, and has published his CV
          on the platform.
        </li>
        <li>
          <strong>User:</strong> Any individual or entity that accesses or uses
          the Platform.
        </li>
        <li>
          <strong>Course:</strong> An online course offered on the Platform.
        </li>
        <li>
          <strong>Course Materials:</strong> All materials provided in
          connection with a Course, including but not limited to video lectures.
        </li>
        <li>
          <strong>Intellectual Property:</strong> All intellectual property
          rights, including but not limited to copyrights, trademarks, and
          patents, in the Platform and Course Materials.
        </li>
        <li>
          <strong>Student:</strong> A user who has enrolled in a Course on the
          Platform.
        </li>
        <li>
          <strong>Content:</strong> Any text, images, videos, or other material
          available on the Platform.
        </li>
        <li>
          <strong>Payment Method:</strong> The method by which a User pays for
          Products, including credit/debit cards, PayPal, or other payment
          gateways supported by the Platform.
        </li>
        <li>
          <strong>Privacy Policy:</strong> The document outlining how the
          Company collects, uses, and protects Users' personal information.
        </li>
        <li>
          <strong>User Content:</strong> Any content, including reviews,
          comments, and uploads, that a User submits to the Platform.
        </li>
      </ul>

      <h2>3. Account Creation and Use</h2>
      <p>
        To enroll in a Course, you must create an Account. When creating an
        Account, you must provide accurate and complete information. You are
        responsible for maintaining the confidentiality of your Account
        credentials and for all activity that occurs under your Account. You
        agree to immediately notify the Company of any unauthorized use of your
        Account or any other breach of security.
      </p>

      <h2>4. Course Enrollment</h2>
      <p>
        To enroll in a course at “NovaLaw”, you must create an account and pay
        the applicable course fee. Once you have enrolled in a course, you will
        have access to the course materials and resources for the duration of
        the course.
      </p>
      <p>
        You may not share your course enrollment with anyone else. If “NovaLaw”
        discovers that you have shared your course enrollment, your account may
        be terminated and you may be banned from using the platform.
      </p>

      <h2>5. Intellectual Property</h2>
      <p>
        The Company owns all Intellectual Property in the Platform and Course
        Materials. You are granted a limited, non-exclusive, non-transferable
        license to access and use the Platform and Course Materials for your
        personal, non-commercial use. You may not download, copy, distribute, or
        otherwise reproduce any of the Course Materials without the Company's
        prior written consent.
      </p>

      <h2>6. Conduct</h2>
      <p>
        You agree to use the Platform and Course Materials in a respectful and
        responsible manner. You will not engage in any of the following
        activities:
      </p>
      <ul>
        <li>Sharing your Account credentials with others.</li>
        <li>Enrolling in a Course on behalf of another person.</li>
        <li>
          Posting any content that is illegal, harmful, threatening, abusive,
          harassing, defamatory, obscene, vulgar, pornographic, libelous, or
          invasive of another person's privacy.
        </li>
        <li>
          Disrupting the flow of a Course or otherwise interfering with the
          enjoyment of other Students.
        </li>
      </ul>

      <h2>7. Payment Method</h2>
      <p>
        Users agree to provide valid and current payment information at the time
        of purchase. The Platform accepts various payment methods, including
        credit and debit cards, PayPal, and other payment gateways as listed on
        the checkout page. Users authorize the Company to charge the selected
        payment method for the total amount of the purchase, including any
        applicable taxes.
      </p>

      <h2>8. Refunds</h2>
      <p>
        <strong>Eligibility for Refunds</strong>
        <br />
        For the Courses: Students are eligible for a full refund of their
        enrollment fee within 3 days of enrolling in a Course, provided the
        student has not started watching the course. After 3 days, no refunds
        will be granted.
      </p>
      <p>
        <strong>For Legal Consultation:</strong>
        <br /> Clients are eligible for a full refund of their legal
        consultation fees within 10 days, in case the assigned lawyer did not
        attend the booked meeting. The client should raise this issue within a
        maximum of 2 days to our customer service department. NovaLaw’s team
        will assign a new lawyer, or if the client declines this service, they
        are entitled to a full refund.
      </p>

      <p>
        <strong>How to Request a Refund</strong>
        <br />
        To request a refund, Students must submit a written request to{" "}
        <a href="mailto:contact@novalaw.legal">Contact@novalaw.legal</a>. The
        request must include the Student's name, Account email address, and the
        name of the Course.
      </p>

      <p>
        <strong>Processing Refunds</strong>
        <br />
        Refunds will be processed within 7 to 10 business days of receiving the
        Student's request. Refunds will be issued to the original payment method
        used to purchase the Course.
      </p>

      <h2>9. Data Security and User Acknowledgment</h2>
      <p>
        The Company stores your personal information on our servers and may use
        it to provide you with our services, communicate with you, and improve
        our services. We will not intentionally share your personal information
        with anyone else, except when required by law or to enforce these terms
        of use. By using our site, you agree to our privacy policy. If you do
        not agree, please do not use our site.
      </p>

      <p>
        The company will make reasonable efforts to limit unauthorized access to
        data and files. However, no system can guarantee complete
        invulnerability. It is conceivable for unauthorized third parties to
        potentially gain access to data you store on the site. Your use of the
        site is entirely at your own risk.
      </p>

      <h2>10. Digital Content Access Support</h2>
      <p>
        If you have any problems accessing digital content you have purchased,
        please contact our Customer Support Department immediately so we can
        resolve the issue.
      </p>

      <h2>11. Disclaimer</h2>
      <p>
        <strong>For the courses:</strong> The Company makes no representations
        or warranties, express or implied, as to the accuracy, completeness, or
        timeliness of the Course Materials. The Company also makes no
        representations or warranties as to your ability to learn from the
        Courses or achieve any desired results.
      </p>

      <p>
        <strong>For Legal Consultation:</strong> The Company makes no
        representations or warranties, express or implied, as to the accuracy,
        timeliness, or completeness of the legal consultation results. NovaLaw
        Consultancy LTD does not take responsibility for the information shared
        and action plan provided by the assigned lawyer.
      </p>

      <h2>12. Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, the Company shall not be liable
        for any direct, indirect, incidental, special, or consequential damages
        arising out of or in connection with your use of the Platform or Course
        Materials.
      </p>

      <h2>13. Governing Law and Jurisdiction</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws. Any dispute arising out of or in connection with these Terms shall
        be subject to the exclusive jurisdiction of the Lebanese laws.
      </p>

      <h2>14. Class Action Waiver</h2>
      <p>
        Any legal action arising out of or relating to this Agreement shall be
        brought in Arbitration.
        <ol>
          <li>
            <strong> Good Faith Negotiations:</strong> In the event of any
            dispute, controversy, or claim arising out of or relating to this
            MOU, the parties agree to first attempt to resolve the dispute
            through good faith negotiations. Either party may initiate
            negotiations by providing written notice of the dispute to the other
            party, outlining the nature of the disagreement and suggesting a
            meeting time and place.
          </li>

          <li>
            {" "}
            <strong>Mediation:</strong> If the dispute cannot be resolved
            through good faith negotiations within 10 days of notice, the
            parties agree to submit the matter to mediation. The mediation will
            be conducted by a mutually agreed-upon mediator, and each party will
            bear its own costs associated with the mediation, with the costs of
            the mediator being shared equally.
          </li>

          <li>
            <strong>Arbitration:</strong>If the mediation is unsuccessful or if
            the parties are unable to agree on a mediator within 7 days, the
            dispute shall be resolved through binding arbitration. The
            arbitration will be conducted in accordance with the rules of the
            Tripoli bar association. The decision of the arbitrator shall be
            final and binding upon the parties, and judgment upon the award
            rendered by the arbitrator may be entered in any court having
            jurisdiction.
          </li>

          <li>
            <strong>Confidentiality of Proceedings:</strong> All negotiations,
            mediations, and arbitration proceedings shall be confidential, and
            the parties agree not to disclose any information related to these
            proceedings to any third party except as required by law.
          </li>

          <li>
            <strong>Costs and Fees:</strong> Each party shall bear its own costs
            and legal fees associated with the ADR process, except as otherwise
            determined by the arbitrator or agreed upon by the parties.
          </li>

          <li>
            <strong>Preservation of Rights:</strong> Participation in ADR does
            not preclude either party from seeking interim relief from a court
            of competent jurisdiction to protect its rights or preserve the
            status quo pending the completion of ADR proceedings..
          </li>
        </ol>
      </p>

      <h2>15. Waiver</h2>
      <p>
        No waiver of any provision of these Terms shall be effective unless in
        writing and signed by both parties.
      </p>

      <h2>16. Changes to the Terms</h2>
      <p>
        The Company reserves the right to change these Terms at any time. Any
        changes to the Terms will be posted on the Platform. Your continued use
        of the Platform after any changes to the Terms will be deemed your
        acceptance of those changes.
      </p>

      <h2>17. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact the Company
        at <a href="mailto:contact@novalaw.legal">Contact@novalaw.legal</a>.
      </p>

      <h2>18. Entire Agreement</h2>
      <p>
        These Terms constitute the entire agreement between you and the Company
        with respect to your use of the Platform and Course Materials.
      </p>
    </div>
  );
};

export default TermsAndConditions;
