const initialState = {
  path: sessionStorage.getItem("path") || "/",
  navigation: [
    {
      id: 1,
      title: "Home",
      path: "/",
    },
    {
      id: 2,
      title: "About",
      path: "/about",
    },
    {
      id: 3,
      title: "Services",
      path: "/services",
    },
    {
      id: 4,
      title: "Contracts",
      path: "/contracts",
    },
    {
      id: 5,
      title: "Lawyers",
      path: "/find-a-lawyer",
    },
    {
      id: 7,
      title: "Trainings",
      path: "/members/courses",
    },
    {
      id: 9,
      title: "Seminars",
      path: "/seminars",
    },
    {
      id: 6,
      title: "Contact Us",
      path: "/contact",
    },
  ],
  services: [
    {
      name: "Seek Legal Counsel",
      desc: "Our lawyer directory connects you with a network of qualified attorneys specializing in diverse legal areas, ensuring you receive the best possible representation.",
      path: "/find-a-lawyer",
      icon: "bi bi-search",
      title: "Find the right lawyer for your needs",
      image: "https://i.ibb.co/wYtTTbs/unnamed-2.png",
    },
    {
      name: "Ready-to-Use Contracts",
      desc: "Our AI-powered contract generator simplifies the process of creating legally binding agreements, saving you time and resources.",
      path: "/contracts",
      icon: "bi bi-file-earmark-text",
      title: "Get the right contract for your needs",
      image: "https://i.ibb.co/34XcnrY/unnamed-1.png",
    },
    {
      name: "Recorded Learning Sessions",
      desc: " Attend our comprehensive legal trainings and workshops designed to keep you informed about the latest legal developments and equip you with valuable skills.",
      path: "/members",
      icon: "bi bi-book",
      title: "Enhance your legal expertise",
      image: "https://i.ibb.co/Fb9Cbym/unnamed.png",
    },
    {
      name: "Monthly Seminars",
      desc: "Join us every month for engaging live discussions on a wide range of legal topics, featuring expert speakers from the field.",
      path: "/seminars",
      icon: "bi bi-shield",
      title: "NovaLaw Monthly Seminars",
      image: "https://i.ibb.co/mDJSpSF/unnamed-4.png",
    },
  ],
  token: localStorage.getItem("token") || null,
  ltoken: localStorage.getItem("ltoken") || null,
  modal: false,
  user: localStorage.getItem("user") || null,
  verificationType: localStorage.getItem("verificationType") || "email",
};

const rootReducer = (state = initialState, action: any) => {
  console.log(action);
  switch (action.type) {
    case "SET_PATH":
      localStorage.setItem("path", action.payload);
      return {
        ...state,
        path: action.payload,
      };
    case "SET_MODAL":
      return {
        ...state,
        modal: action.payload,
      };
    case "SET_TOKEN":
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        token: action.payload,
      };
    case "SET_LTOKEN":
      localStorage.setItem("ltoken", action.payload);
      return {
        ...state,
        ltoken: action.payload,
      };
    case "SET_USER":
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      };
    case "SET_VERIFICATION_TYPE":
      localStorage.setItem("verificationType", action.payload);
      return {
        ...state,
        verificationType: action.payload,
      };
    default:
      break;
  }
  return state;
};
export default rootReducer;
